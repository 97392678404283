import { chakra, useMultiStyleConfig, ChakraProps } from "@chakra-ui/react";
import React, {
	BaseSyntheticEvent,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import {
	FieldValues,
	FormProvider,
	useFormContext,
	UseFormReturn,
} from "react-hook-form";
import {
	useGetAllInsuranceProductsQuery,
	useGetAllTargetGroupsQuery,
} from "src/__generated__/client.codegen";
import { FormSubmitButton } from "src/components/FormSubmitButton";
import { Hx } from "src/components/Heading/Heading";
import { InfoTooltip } from "src/components/InfoTooltip/InfoTooltip";
import { FormSelect } from "src/components/Inputs/FormSelect";
import { Loading } from "src/components/Loading/Loading";
import { filterInsuranceProductsForForm } from "src/components/PremiumCalcModal/utils";
import { useTranslatedString } from "src/i18n/i18n";
import { Checkmark, Submit } from "src/icons";
import {
	usePortalType,
	useRole,
	useSiteSettings,
	useTargetGroup,
} from "src/lib/hooks";
import { isInternalOnlyTargetGroup } from "src/lib/productAndTGFilters";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { useErrorToast } from "src/lib/toasts";
import { z } from "src/lib/zod";
import { useLoginInfo } from "src/queries/emil/account";
import { useSendEmail } from "src/queries/emil/email";
import { TypeOf } from "zod";

export const commonEmailPropsSchema = z.object({
	emailSubject: z.string(),
	targetGroupSlug: z.string().min(1).or(z.null()),
	insuranceProduct: z.string().min(1).or(z.null()),
});

type ContactFormProps<ZodSchema extends FieldValues> = ChakraProps & {
	children: ReactElement | Array<ReactElement>;
	buttonText: string;
	isValid: boolean;
	type: "message" | "callback" | "demo";
	form: UseFormReturn<ZodSchema>;
};
export const ContactForm = <ZodSchema extends FieldValues>({
	buttonText,
	isValid,
	children,
	type,
	form,
	...rest
}: ContactFormProps<ZodSchema>) => {
	const styles = useMultiStyleConfig("ContactForm");
	const t = useTranslatedString();
	const {
		brandConfig: { slug: brandSlug },
		locale,
	} = useSiteSettings();
	const { data: insuranceProductData } = useGetAllInsuranceProductsQuery({
		locale,
	});

	const { data: targetGroupData } = useGetAllTargetGroupsQuery({
		locale,
	});

	const sendEmail = useSendEmail();
	const toast = useErrorToast();
	const targetGroupCtx = useTargetGroup();

	const { handleSubmit } = form;

	const onSubmit = useCallback(
		(e: BaseSyntheticEvent) => {
			handleSubmit(
				({
					emailSubject,
					insuranceProduct,
					targetGroupSlug,
					...payload
				}) => {
					const templateSlugAccessor = `${type}FormSlug` as const;

					// insuranceProduct will be null if there's targetGroupCtx (FuBIS, THNOVO)
					if (!insuranceProduct) {
						const userSelectedTargetGroup =
							targetGroupData?.allTargetGroups.find(
								(tg) => tg.slug === targetGroupSlug,
							);

						const emailTemplateSlug =
							userSelectedTargetGroup?.[templateSlugAccessor];

						if (!emailTemplateSlug) {
							// Something went wrong on our end (CMS content)
							toast(t("toast.formSubmitError"));

							return;
						}

						sendEmail.mutate({
							emailSubject: `[${brandSlug}] ${emailSubject} ${userSelectedTargetGroup.name}`,
							emailTemplateSlug,
							payload,
						});

						return;
					}

					// get all products that fit the insurance product slug
					const userSelectedProducts =
						insuranceProductData?.allInsuranceProducts.filter(
							(product) => product.slug === insuranceProduct,
						);

					// search in these product for the one that has the given tg slug
					const userSelectedProduct = userSelectedProducts?.find(
						({ targetGroup }) =>
							targetGroup && targetGroup.slug === targetGroupSlug,
					);

					// if the selected product has a target group, use its template slug; if not, use the product's template slug
					const emailTemplateSlug = userSelectedProduct?.targetGroup
						? userSelectedProduct.targetGroup[templateSlugAccessor]
						: userSelectedProducts?.[0]?.[templateSlugAccessor];

					if (!emailTemplateSlug) {
						// Something went wrong on our end (CMS content)
						toast(t("toast.formSubmitError"));

						return;
					}

					const productName = userSelectedProduct?.name;
					const targetGroupName =
						userSelectedProduct?.targetGroup?.name;
					const additionalInfo = `${productName ? ` ${productName}` : ""}${targetGroupName ? ` ${targetGroupName}` : ""}`;

					sendEmail.mutate({
						emailSubject: `[${brandSlug}] ${emailSubject}${additionalInfo}`,
						emailTemplateSlug,
						payload,
					});
				},
			)(e);
		},
		[
			brandSlug,
			insuranceProductData?.allInsuranceProducts,
			handleSubmit,
			sendEmail,
			t,
			targetGroupData?.allTargetGroups,
			toast,
			type,
		],
	);

	// TODO: 🚧 Better error handling

	if (sendEmail.isSuccess) {
		return (
			<chakra.div __css={styles.formWrapper}>
				<chakra.div sx={styles.content}>
					<chakra.div sx={styles.formSent}>
						<Checkmark
							width={14}
							height={14}
							color="brand.highlight"
						/>
						<Hx size="h3">{t("sent")}</Hx>
					</chakra.div>
				</chakra.div>
			</chakra.div>
		);
	}

	return (
		<FormProvider {...form}>
			<chakra.div
				__css={styles.formWrapper}
				{...rest}
				data-tgroup={targetGroupCtx?.slug ?? "<unknown>"}
			>
				<chakra.form
					sx={styles.content}
					onSubmit={onSubmit}
					id={`form_${type}`}
				>
					{children}
					<chakra.div sx={styles.buttonWrapper}>
						<FormSubmitButton
							isValid={isValid}
							rightIcon={<Submit />}
							width="100%"
							isLoading={sendEmail.isLoading}
							forceDisabled={sendEmail.isLoading}
							id={`submit_contact_form_${type}`}
						>
							{buttonText}
						</FormSubmitButton>
					</chakra.div>
				</chakra.form>
			</chakra.div>
		</FormProvider>
	);
};

export const ContactFormTitle: React.FC<{
	children: ReactElement | Array<ReactElement>;
}> = ({ children }) => {
	const styles = useMultiStyleConfig("ContactForm");
	const t = useTranslatedString();

	return (
		<chakra.div sx={styles.formTitleWrapper}>
			<chakra.div sx={styles.formTitle}>{children}</chakra.div>
			<InfoTooltip text={t("contact.consent")} />
		</chakra.div>
	);
};

export const ContactFormProductSelect: React.FC = () => {
	const { locale } = useSiteSettings();
	const { data: targetGroupData, isLoading: isLoadingTargetGroups } =
		useGetAllTargetGroupsQuery({
			locale,
		});
	const { data, isLoading: isLoadingInsuranceProducts } =
		useGetAllInsuranceProductsQuery({
			locale,
		});
	const {
		formState: { errors },
		watch,
		setValue,
	} = useFormContext<TypeOf<typeof commonEmailPropsSchema>>();
	const targetGroupCtx = useTargetGroup();
	const styles = useMultiStyleConfig("ContactForm");
	const t = useTranslatedString();
	const loginInfo = useLoginInfo();
	const rolePermission = useRole();
	const portal = usePortalType();

	const validTargetGroupCtx = useMemo(
		() =>
			targetGroupCtx &&
			!isInternalOnlyTargetGroup(targetGroupCtx) &&
			targetGroupCtx,

		[targetGroupCtx],
	);

	const currentProductSlug = watch("insuranceProduct");

	const insuranceProducts = useMemo(() => {
		const products = filterInsuranceProductsForForm(
			data?.allInsuranceProducts,
			loginInfo,
			rolePermission,
			portal,
		).filter((product) => Boolean(product.showContactForm));

		return products;
	}, [data, loginInfo, portal, rolePermission]);

	const targetGroups = useMemo(() => {
		return insuranceProducts
			.filter(
				({ slug: productSlug }) => currentProductSlug === productSlug,
			)
			.flatMap(({ targetGroups }) => targetGroups)
			.filter(
				(
					targetGroup,
				): targetGroup is NonNullable<
					(typeof insuranceProducts)[number]["targetGroup"]
				> =>
					Boolean(targetGroup) &&
					Boolean(targetGroup.showContactForm),
			);
	}, [currentProductSlug, insuranceProducts]);

	useEffect(() => {
		if (validTargetGroupCtx) {
			setValue("insuranceProduct", null);
			setValue("targetGroupSlug", validTargetGroupCtx.slug);
		}

		// if there are no target groups for products with the current slug, set targetGroupSlug to null
		if (targetGroups.length) {
			setValue("targetGroupSlug", "");
		} else {
			setValue("targetGroupSlug", null);
		}
	}, [
		currentProductSlug,
		setValue,
		targetGroups.length,
		validTargetGroupCtx,
	]);

	if (validTargetGroupCtx) {
		return null;
	}

	if (isLoadingTargetGroups || isLoadingInsuranceProducts) {
		<chakra.div __css={styles.formWrapper}>
			<chakra.div sx={styles.content}>
				<Loading />
			</chakra.div>
		</chakra.div>;
	}

	if (!targetGroupData?.allTargetGroups || !data?.allInsuranceProducts) {
		return null;
	}

	return (
		<>
			<FormSelect
				name="insuranceProduct"
				label={t("contact.form.insuranceProduct.label")}
				placeholder={t("contact.form.insuranceProduct.placeholder")}
				isInvalid={Boolean(errors.insuranceProduct)}
				sx={styles.formControl}
			>
				{insuranceProducts.map((product) => (
					<option key={product.slug} value={product.slug}>
						{replacePipeWithShy(product.name)}
					</option>
				))}
			</FormSelect>
			{targetGroups.length ? (
				<FormSelect
					name="targetGroupSlug"
					label={t("contact.form.targetGroupSlug.label")}
					placeholder={t("contact.form.targetGroupSlug.placeholder")}
					sx={styles.formControl}
					isInvalid={Boolean(errors.targetGroupSlug)}
					isDisabled={!targetGroups.length}
				>
					{targetGroups.map(({ slug, name }) => {
						return (
							<option id={slug} key={slug} value={slug}>
								{name}
							</option>
						);
					})}
				</FormSelect>
			) : null}
		</>
	);
};

// 🔬 e2e: contact.spec.ts
