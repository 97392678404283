import {
	chakra,
	useMultiStyleConfig,
	// eslint-disable-next-line no-restricted-imports
	Textarea as ChakraTextarea,
	TextareaProps as ChakraTextareaProps,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { useTranslatedString } from "src/i18n/i18n";

type TextareaProps = ChakraTextareaProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ value, maxLength, ...rest }, ref) => {
		const styles = useMultiStyleConfig("TextareaWithCounter");
		const t = useTranslatedString();

		const showCounter = Boolean(maxLength);
		const length = value ? value.toString().length : 0;

		return (
			<chakra.div __css={styles.wrapper}>
				<ChakraTextarea
					value={value}
					ref={ref}
					maxLength={maxLength}
					{...rest}
				/>
				{showCounter && (
					<chakra.p __css={styles.counter}>
						{t("textarea.counter", { length, maxLength })}
					</chakra.p>
				)}
			</chakra.div>
		);
	},
);
Textarea.displayName = "Textarea";

// 🔬 TBD: Please evaluate
